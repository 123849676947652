<template>
  <nav class="navbar navbar-expand-md">
    <!-- Hamburger -->

    <button @click="hamburgerClicked" class="navbar-toggler" type="button" aria-controls="navigation-items" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fa fa-bars"></i>
    </button>

    <!-- Brand -->

    <router-link class="navbar-brand d-flex w-50 mr-auto" :to="{ name: 'home' }">
      <img v-if="user.isAnonymous" class="logo" src="/static/images/logo.png" />
      {{ brand }}
    </router-link>

    <!-- Navigation items (only for anonymous (i.e. unauthenticated) users). -->

    <div v-if="user.isAnonymous" class="collapse navbar-collapse" id="navigation-items">
      <ul v-for="(group, index) in actionGroups" :key="index" class="navbar-nav ml-auto">

        <!-- Navigation items -->

        <li class="nav-item" v-for="action in group" :key="action.title">
          <!-- N.B. We use the 'data-title' attribute of the router link in the automated (selenium) tests. -->
          <router-link class="nav-link" :to="{ name: action.routeName }" data-toggle="collapse" data-target="#navigation-items" :data-title="action.title">
            <span :class="['action-icon', 'fa fa-fw fa-' + action.iconName]" aria-hidden="true"></span>
            {{ action.title }}
          </router-link>
        </li>

        <!-- Login -->

        <li class="nav-item login">
          <router-link class="nav-link" :to="{ name: 'login' }" data-toggle="collapse" data-target="#navigation-items" data-title="Log In">
            <span class="fa fa-sign-in-alt"></span> Log In
          </router-link>
        </li>
      </ul>
    </div>

    <!-- Logout (when authenticated!) -->

    <ul v-else class="navbar-nav">
      <li class="nav-item logout" v-if="user.isAuthenticated">
        <router-link class="nav-link" :to="{ name : 'logout' }" data-toggle="collapse" data-target="#navigation-items" data-title="Log Out">
          <span class="fa fa-sign-out-alt"></span> Log Out
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
import navigation from '@/lib/site/navigation';


export default {
  name: 'Navbar',

  computed: {
    brand() {
      let path = this.$route.path;
      let brand = 'ACT Peer Recovery';

      // For demoing DART to make it look like it is its own website :)
      if (path.startsWith('/dart/')) {
        brand = 'DART';

      // To make it look like P.R. has its wown website :)
      } else if (path.startsWith('/chats/')) {
        brand = 'Pause. Recover.';
      }

      document.title = brand;

      return brand
    },

    user() {
      return this.$store.getters['auth/user']
    },

    actionGroups() {
      return navigation.actionGroups(this.user);
    },
  },

  methods: {
    hamburgerClicked() {
      if (this.user.isAnonymous) {
        window.$('#navigation-items').collapse('toggle');
      } else {
        this.$emit('hamburgerClicked');
      }
    },
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/theme.scss';
@import '@/assets/styles/media-queries.scss';


.navbar {
  position: fixed;
  left:0;
  width: 100vw;

  border-bottom: 1px solid black;
  background-color: $theme-accent-color;
  background-color: $theme-navbar-background-color;
  color: $theme-inverse-color;

  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.navbar-brand {
  color: $theme-color;
  font-size: 1.6rem;
}

.logo {
  margin-left: .4rem;
  margin-right: .8rem;
}

.navbar-toggler {
  margin-left: -10px;
  border: 1px solid transparent;
  padding-top: 7px;

  font-size: 1.8rem;
  color: $theme-color;

  &:hover {
    border: 1px solid $theme-color;
    border-radius: 4px;
  }

  /* Prevent BS default shadow */
  &:focus, &.active {
    outline: none;
    box-shadow: none;
  }
}

.navbar-collapse {
  padding-top: 5px;

  /*
   * Make the hamburger menu transition a quicker than the Bootstrap default :)
   */
  &.collapsing {
    -webkit-transition: height .1s ease;
         -o-transition: height .1s ease;
            transition: height .1s ease;
  }
}

.nav-item .router-link-active {
  color: $theme-text-color !important;
}

.nav-link {
  color : $theme-color !important;

  &:hover {
    color: $theme-text-color !important;
  }
}

@include on(sm-and-smaller) {
  // Chunkier links on xs!
  .nav-link {
    margin-top: 12px;
    font-size: 1.2rem;
  }

  .logout .nav-link {
    margin-top: 4px;
    font-size: 1rem;
  }
}

@include on(md-and-larger) {
  .login {
    margin-left:20px;
  }
}
</style>
