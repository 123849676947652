<template>
  <div class="home">
    <wistia-video videoId="5dia3x4x2c"></wistia-video>
    <h2 class="tag-line">Watch a 2 minute video!</h2>
  </div>
</template>

<script>
import WistiaVideo from '@/lib/media/components/WistiaVideo.vue'

export default {
  name: 'home',

  components: {
    WistiaVideo
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/media-queries.scss';
@import '@/assets/styles/theme.scss';

.home {
  text-align: center;

  @include on(md-and-larger) {
    margin-top: 10vh;
  }
}

.tag-line {
  color: $theme-text-color;
}

.wistia-video {
  width: 70%;
  height: auto;
}
</style>
