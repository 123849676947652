import accountsService from '@/lib/accounts/services';


export const ACTIVATE_BEGIN   = 'ACTIVATE_BEGIN';
export const ACTIVATE_SUCCESS = 'ACTIVATE_SUCCESS';
export const ACTIVATE_FAILURE = 'ACTIVATE_FAILURE';
export const ACTIVATE_CLEAR   = 'ACTIVATE_CLEAR';


export default {
  namespaced: true,

  state: {
    loading: false,
    failed: false,
    errors: {}
  },

  actions: {
    activate({ commit }, { uid, token }) {
      commit(ACTIVATE_BEGIN);
      return accountsService.activate(uid, token)
        .then(() => commit(ACTIVATE_SUCCESS))
        .catch((error) => commit(ACTIVATE_FAILURE, error.response.data));
    },

    clear({ commit }) {
      commit(ACTIVATE_CLEAR);
    },
  },

  mutations: {
    [ACTIVATE_BEGIN](state) {
      state.loading = true;
      state.failed = false;
      state.errors = {};
    },

    [ACTIVATE_SUCCESS](state) {
      state.loading = false;
      state.failed = false;
      state.errors = {};
    },

    [ACTIVATE_FAILURE](state, errors) {
      state.loading = false;
      state.failed = true;
      state.errors = errors;
    },

    [ACTIVATE_CLEAR](state) {
      state.loading = false;
      state.failed = false;
      state.errors = {};
    },
  },
};
