import axios from 'axios';
import router from '@/router';


const CSRF_COOKIE_NAME = 'csrftoken';
const CSRF_HEADER_NAME = 'X-CSRFToken';

const session = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  xsrfCookieName: CSRF_COOKIE_NAME,
  xsrfHeaderName: CSRF_HEADER_NAME,
});


function errorHandler(error) {
  // The request was made and the server returned a response with a status code
  // that falls out of the range of 2xx.
  if (error.response) {
    // 403 (Forbidden) means the user needs to be logged in!
    if (error.response.status == 403) {
      router.push({name : 'login', query: {redirect: router.currentRoute.path}})

    // 404 (Not Found) means, well, you know what it means :)
    } else if (error.response.status == 404) {
      router.push({name: 'not-found'})

    // 422 is used for failed validation.
    } else if (error.response.status == 422) {
      return Promise.reject(error);

    // 400 (Bad Request) is what we get if we post/patch invalid data (via
    // forms etc), so let that go! Anything else means something went
    // unexpectedly wrong!
    } else if (error.response.status != 400) {
        router.push({name : 'oops', params: {error: error, errorMessage: 'Doh'}})
    }

  // The request was made but no response was received.
  //
  // `error.request` is an instance of XMLHttpRequest in the browser and an
  // instance of http.ClientRequest in node.js.
  } else if (error.request) {
    var routeName = error.message == 'Network Error' ? 'offline' : 'oops';
    router.push({name : routeName, params: {error: error, errorMessage: error.message}})

  // Otherwise, something went wrong setting up the request!
  } else {
    router.push({name : 'oops', params: {error: error, errorMessage: error.message}})
  }

  return Promise.reject(error);
}

session.interceptors.response.use(null, errorHandler);

export default session;
