import accountsService from '@/lib/accounts/services';
import User from '@/lib/accounts/user';


export const LOGIN_BEGIN   = 'LOGIN_BEGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGOUT        = 'LOGOUT';
export const SET_TOKEN     = 'SET_TOKEN';
export const REMOVE_TOKEN  = 'REMOVE_TOKEN';


const USER_STORAGE_KEY  = 'USER';


function initialState() {
  return {
    // Lifecycle.
    authenticating: false,
    error: false,

    // Data.
    user: JSON.parse(localStorage.getItem(USER_STORAGE_KEY, null))
  }
}

const getters = {
  user: (state) => new User(state.user)
};

const actions = {
  login({ commit }, { username, password }) {
    commit(LOGIN_BEGIN);
    return accountsService.login(username, password)
      .then((user) => commit(SET_TOKEN, user))
      .then(() => commit(LOGIN_SUCCESS))
      .catch(() => commit(LOGIN_FAILURE));
  },

  logout({ commit }) {
    return accountsService.logout()
      .then(() => commit(LOGOUT))
      .finally(() => commit(REMOVE_TOKEN));
  },
};

const mutations = {
  [LOGIN_BEGIN](state) {
    state.authenticating = true;
    state.error = false;
  },
  [LOGIN_SUCCESS](state) {
    state.authenticating = false;
    state.error = false;
  },
  [LOGIN_FAILURE](state) {
    state.authenticating = false;
    state.error = true;
  },
  [LOGOUT](state) {
    state.authenticating = false;
    state.error = false;
  },
  [SET_TOKEN](state, user) {
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
    state.user  = user;
  },
  [REMOVE_TOKEN](state) {
    localStorage.removeItem(USER_STORAGE_KEY);
    state.user  = null;
  },
};

export default {
  namespaced: true,
  state: initialState(),
  getters,
  actions,
  mutations,
};
