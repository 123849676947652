import accountsService from '@/lib/accounts/services';


export const PASSWORD_RESET_REQUEST_BEGIN   = 'PASSWORD_RESET_REQUEST_BEGIN';
export const PASSWORD_RESET_REQUEST_SUCCESS = 'PASSWORD_RESET_REQUEST_SUCCESS';
export const PASSWORD_RESET_REQUEST_FAILURE = 'PASSWORD_RESET_REQUEST_FAILURE';
export const PASSWORD_RESET_REQUEST_CLEAR   = 'PASSWORD_RESET_REQUEST_CLEAR';


export default {
  namespaced: true,
  state: {
    loading: false,
    completed: false,
    error: false,
  },

  actions: {
    passwordResetRequest({ commit }, { email }) {
      commit(PASSWORD_RESET_REQUEST_BEGIN);
      return accountsService.passwordResetRequest(email)
        .then(() => commit(PASSWORD_RESET_REQUEST_SUCCESS))
        .catch(() => commit(PASSWORD_RESET_REQUEST_FAILURE));
    },

    clear({ commit }) {
      commit(PASSWORD_RESET_REQUEST_CLEAR);
    },
  },

  mutations: {
    [PASSWORD_RESET_REQUEST_BEGIN](state) {
      state.loading = true;
    },
    [PASSWORD_RESET_REQUEST_SUCCESS](state) {
      state.loading = false;
      state.completed = true;
      state.error = false;
    },
    [PASSWORD_RESET_REQUEST_FAILURE](state) {
      state.loading = false;
      state.error = true;
    },
    [PASSWORD_RESET_REQUEST_CLEAR](state) {
      state.loading = false;
      state.completed = false;
      state.error = false;
    },
  },
};
