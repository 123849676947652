//
// The client-side of the accounts api.
//
// Top-tip:
//
// Split your editor window and bring up the backend URLs in the other one!
// (in this case that would be 'neo.apps.accounts.urls'). Then you can see
// how we keep the naming conventions are consistent from front-back! :)
//
import session from '@/session';


const ACCOUNTS = '/api/accounts/';


export default {
  login(username, password) {
    return session.post(ACCOUNTS + 'login/', { username, password }).then(function(response) {
      return window.firebase.auth().signInWithCustomToken(response.data.token).then(function() {
        return window.firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(/* idToken */) {
          return response.data.user
        })
      })
    })
  },

  loginWithoutFirebase(username, password) {
    return session.post(ACCOUNTS + 'login/', { username, password }).then(function(response) {
      return response.data.user
    })
  },

  logout() {
    return session.post(ACCOUNTS + 'logout/', {}).then(() => window.firebase.auth().signOut())
  },

  passwordResetRequest(email) {
    return session.post(ACCOUNTS + 'password/reset/request/', { email });
  },

  passwordReset(uid, token, password, passwordToo) {
    return session.post(ACCOUNTS + 'password/reset/', { uid, token, password, passwordToo })
  },
  //
  // fixme: This is only used when creating a passwordless account during a meeting!
  //
  create({ username, postalCode, gender ,ageGroup, substance, referral }) {
    const profile = { postalCode, gender, ageGroup, substance, referral };
    return session.post(ACCOUNTS, { username, profile })
  },
  //
  // Currently only used in development.
  //
  activate(uid, token) {
    return session.post(ACCOUNTS + 'activate/', { uid, token })
  },

  signup(username, email, password, passwordToo, name) {
    const profile = { name };
    return session.post(ACCOUNTS + 'signup/', { username, email, password, passwordToo, profile });
  },

  unsubscribe(uid, token) {
    return session.post(ACCOUNTS + 'unsubscribe/', { uid, token })
  },
};
