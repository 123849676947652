<template>
  <nav class="sidebar" :class="{ expanded }">

    <!-- Logo -->

    <div class="logo">
      <router-link :to="{ name: 'home' }">
        <img src="/static/images/logo.png" />
      </router-link>
    </div>

    <!-- Navigation Items -->

    <ul class="nav">
      <div v-for="(group, index) in actionGroups" :key="index" class="w-100">
        <li class="nav-item" v-for="action in group" :key="action.title" :title="!expanded ? action.title : ''">
           <!-- N.B. We use the 'data-title' attribute of the router link in the automated (selenium) tests. -->
          <router-link class="nav-link" :to="{ name : action.routeName }" :data-title="action.title">
            <span :class="'fa fa-fw fa-' + action.iconName"></span>
            <span v-show="expanded" class="action-title">{{ action.title }}</span>
          </router-link>
        </li>
        <hr class="divider"/>
      </div>
    </ul>

    <!-- Expand/collapse toggle -->

    <div v-if='showToggle' @click="$emit('toggleSidebar')" class="toggle nav-link">
      <span :class="'fas fa-chevron-' + (expanded ? 'left' : 'right')"></span>
    </div>
  </nav>
</template>

<script>
import navigation from '@/lib/site/navigation';

export default {
  name: 'Sidebar',

  props: {
    expanded: {
      type: Boolean,
      default: false
    },

    showToggle: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    actionGroups() {
      return navigation.actionGroups(this.user);
    },
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/styles/theme.scss';
@import '@/assets/styles/media-queries.scss';

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  width: $theme-sidebar-width;
  &.expanded {
    width: $theme-sidebar-expanded-width;
  }

  padding: 10px 10px 0 5px;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);

  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */

  background-color: $theme-accent-color;

  -webkit-transition: width 0.3s;
  -o-transition: width 0.3s;
  transition: width 0.3s;

  /*
   * Children.
   */

  .logo {
    margin-top: 8px;
    margin-bottom: 20px;
    margin-left: 12px;
  }

  .nav-link {
    padding: 0 5px 1rem 1rem;
    border-left: 3px solid transparent;

    white-space: nowrap;
    overflow: hidden;

    font-size: 1.2rem;
    font-weight: 500;
    color: $theme-inverse-color;
  }

  .nav-item .router-link-exact-active {
    border-left: 3px solid $theme-inverse-color;
  }

  .action-title {
    margin-left: 6px;
    font-size: 1rem;
  }

  .toggle {
    margin-left: 5px;
    cursor: pointer;
  }
}
</style>
