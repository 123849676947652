<template>
  <div class="overlay"></div>
</template>

<script>
export default {
  name: 'Overlay',
};
</script>

<style scoped lang="scss">
.overlay {
  display: none;
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;

  background: rgba(0, 0, 0, 0.7);
  opacity: 0;

  transition: all 0.3s ease-in-out;
}
</style>
