var Util = {};

Util.isEmptyObject = function(obj) {
 for (var x in obj) { return false; }
 return true;
};

/*
 * Move the cursor to the end of the given input element and select all of
 * its contents.
 *
 * This is useful when (say) an input on a form has an error and you want to
 * make it easy for the user to clear the input and start again.
 */
Util.selectAll = function(inputId) {
  var input = document.getElementById(inputId);

  // Multiply by 2 to make sure that the cursor *always* ends up at the end!
  // Opera sometimes sees a carriage return as 2 characters :(
  var length = input.value.length * 2;

  input.focus();
  input.setSelectionRange(0, length);
};

Util.downloadFile = function(response, filename) {
  var fileURL = window.URL.createObjectURL(new Blob([response.data]));
  var fileLink = document.createElement('a');
  fileLink.href = fileURL;
  fileLink.setAttribute('download', filename);
  document.body.appendChild(fileLink);
  fileLink.click();
};

Util.onSMAndSmaller = function() {
  return window.innerWidth <= 767;
};

Util.dateInRange = function(date, startDate, endDate) {
  date = new Date(date.valueOf());
  date.setHours(0, 0, 0, 0);

  if (startDate) {
    startDate = new Date(startDate.valueOf());
    startDate.setHours(0, 0, 0, 0);

    if (date < startDate) {
      return false;
    }
  }

  if (endDate) {
    endDate = new Date(endDate.valueOf());
    endDate.setHours(0, 0, 0, 0);

    if (date > endDate) {
      return false;
    }
  }

  return true
}

Util.copyToClipboard = function(text) {
  navigator.clipboard.writeText(text);
  const el = document.createElement('textarea');
  el.value = text;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
}

export default Util;