<template>
  <div class="card wistia-video">
    <div class="wistia_responsive_padding" style="padding:55.0% 0 0 0;position:relative;">
      <div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;">
        <div :class="wistiaEmbedClass" style="height:100%;position:relative;width:100%">
          <div class="wistia_swatch" style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"><img :src="wistiaSwatchSrc" style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt="" onload="this.parentNode.style.opacity=1;">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WistiaVideo',

  props: {
    videoId: String,
    popover: Boolean
  },

  computed: {
    wistiaEmbedClass : function() {
      return 'wistia_embed wistia_async_' + this.videoId + ' videoFoam=true' + ' popover=' + this.popover.toString();
    },

    wistiaSwatchSrc : function() {
      return 'https://fast.wistia.com/embed/medias/' + this.videoId + '/swatch';
    }
  },

  mounted() {
    this.addWistiaScriptElement(this.videoId)
  },

  methods: {
    addWistiaScriptElement(videoId) {
      // Make sure we only add the element to the document once :)
      let src = 'https://fast.wistia.com/embed/medias/' + videoId + '.jsonp';
      if (!document.querySelector('script[src="' + src + '"]')) {
        let wistiaScript = document.createElement('script');
        wistiaScript.setAttribute('src', src);
        document.head.appendChild(wistiaScript);
      }
    }
  }
}
</script>

<style scoped>
.wistia-video {
  display: inline-block;
}
</style>