var Groups = {
  STAFF                  : 'Staff',
  SENIOR_MEETING_LEADERS : 'Senior Leaders',
  MEETING_LEADERS        : 'Leaders',
  TRAINEE_MEETING_LEADERS: 'Trainee Leaders',
  PEERS                  : 'Peers',
};


class User {
  constructor(state) {
    this.state = state;
  }

  get id() {
    return this.state.id;
  }

  get username() {
    return this.state.username;
  }

  get isAnonymous() {
    return !this.isAuthenticated
  }

  get isAuthenticated() {
    return !!this.state
  }

  // Permissions //////////////////////////////////////////////////////////////

  can(permission) {
    return this[permission]
  }

  get canListPosts() {
    return this.isAuthenticated
  }

  get canCreatePosts() {
    return this.isStaff
  }

  get canUpdatePosts() {
    return this.isStaff
  }

  get canReadContent() {
    return this.isStaff
  }

  get canLeadMeetings() {
    return this.isStaff || this.isSeniorMeetingLeader || this.isMeetingLeader || this.isTraineeMeetingLeader
  }

  // Group membership /////////////////////////////////////////////////////////

  isInGroup(group) {
    if (!this.isAuthenticated) return false;

    return this.state.groups.includes(group)
  }

  get isStaff() {
    return this.isInGroup(Groups.STAFF);
  }

  get isSeniorMeetingLeader() {
    return this.isInGroup(Groups.SENIOR_MEETING_LEADERS)
  }

  get isMeetingLeader() {
    return this.isInGroup(Groups.MEETING_LEADERS)
  }

  get isTraineeMeetingLeader() {
    return this.isInGroup(Groups.TRAINEE_MEETING_LEADERS)
  }

  get isPeer() {
    return this.isInGroup(Groups.PEERS)
  }
}


export default User;
