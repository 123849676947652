export default {
  /*
   * What an ANONYMOUS (i.e. UNauthenticated) user can do.
   */
  actionGroupsAnonymous: [
    [
      {title: 'About', routeName: 'about', iconName: 'info-circle'},
      {title: 'Contact', routeName: 'contact', iconName: 'envelope'},
    ]
  ],

  /*
   * What an AUTHENTICATED user can do.
   */
  actionGroupsAuthenticated: [
    [
      {title: 'DARTboard', routeName: 'dart-board', iconName: 'bullseye', requires: 'isStaff'},
      {title: 'DARTdesk', routeName: 'dart-desk', iconName: 'ticket', requires: 'isStaff'},
    ],

    [
      {title: 'Help Centres', routeName: 'help-centre-list', iconName: 'wrench', requires: 'isStaff'},
      {title: 'Users', routeName: 'user-list', iconName: 'user', requires: 'isStaff'},
      {title: 'Cohorts', routeName: 'cohort-list', iconName: 'user-friends', requires: 'isStaff'},
      // {title: 'Moderation', routeName: 'chat-moderation', iconName: 'eye', requires: 'isStaff'},
      {title: 'Broadcast', routeName: 'broadcast', iconName: 'bullhorn', requires: 'isStaff'},
    ],

    // [
    //   {title: 'Meetings', routeName: 'meeting-list', iconName: 'handshake', requires: 'canLeadMeetings'},
    //   {title: 'Delivery', routeName: 'delivery', iconName: 'book', requires: 'canLeadMeetings'},
    //   {title: 'Training', routeName: 'training', iconName: 'briefcase', requires: 'canLeadMeetings'},
    // ],
    //
    // [
    //   {title: 'Content', routeName: 'content', iconName: 'piggy-bank', requires: 'canReadContent'},
    // ],
  ],

  actionGroups(user) {
    var actionGroups;

    if (user.isAuthenticated) {
      actionGroups = [];

      this.actionGroupsAuthenticated.forEach((group) => {
        let actionsAllowed = group.filter(action => user.can(action.requires));
        if (actionsAllowed.length > 0) {
          actionGroups.push(actionsAllowed)
        }
      })

    } else {
      actionGroups = this.actionGroupsAnonymous;
    }

    return actionGroups
  },
}
