import accountsService from '@/lib/accounts/services';


export const UNSUBSCRIBE_BEGIN   = 'UNSUBSCRIBE_BEGIN';
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_FAILURE = 'UNSUBSCRIBE_FAILURE';
export const UNSUBSCRIBE_CLEAR   = 'UNSUBSCRIBE_CLEAR';


export default {
  namespaced: true,

  state: {
    loading: false,
    success: false,
    failed: false,
    errors: {},
  },

  actions: {
    unsubscribe({ commit }, { uid, token }) {
      commit(UNSUBSCRIBE_BEGIN);
      return accountsService.unsubscribe(uid, token)
        .then(() => commit(UNSUBSCRIBE_SUCCESS))
        .catch((error) => commit(UNSUBSCRIBE_FAILURE, error.response.data));
    },

    clear({ commit }) {
      commit(UNSUBSCRIBE_CLEAR);
    },
  },

  mutations: {
    [UNSUBSCRIBE_BEGIN](state) {
      state.loading = true;
      state.success = false;
      state.failed = false;
      state.errors = {};
    },

    [UNSUBSCRIBE_SUCCESS](state) {
      state.loading = false;
      state.success = true;
      state.failed = false;
      state.errors = {};
    },

    [UNSUBSCRIBE_FAILURE](state, errors) {
      state.loading = false;
      state.success = false;
      state.failed = true;
      state.errors = errors;
    },

    [UNSUBSCRIBE_CLEAR](state) {
      state.loading = false;
      state.success = false;
      state.failed = false;
      state.errors = {};
    },
  },
};
