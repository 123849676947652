<template>
  <div class="layout" :class="layoutClasses">

    <!-- Sidebar -->

    <sidebar :expanded="sidebarExpanded" @toggleSidebar="toggleSidebar"></sidebar>

    <!-- Navbar -->

    <navbar @hamburgerClicked="toggleSidebar"></navbar>

    <!-- Content -->

    <div id="content">
      <div class="container-fluid mt-3 mb-3">
        <router-view></router-view>
      </div>
    </div>

    <!-- Overlay (used on SM and smaller, and only when authenticated) -->

    <overlay @click.native="toggleSidebar"></overlay>
  </div>
</template>

<script>
import Navbar from '@/lib/site/components/Navbar.vue'
import Overlay from '@/lib/site/components/Overlay.vue'
import Sidebar from '@/lib/site/components/Sidebar.vue'
import Util from '@/lib/common/util.js'


export default {
  name: 'App',

  components: {
    Navbar,
    Overlay,
    Sidebar
  },

  data() {
    return {
      sidebarExpanded: false,
    };
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    layoutClasses() {
      return [
        this.user.isAuthenticated ? 'authenticated-user' : 'anonymous-user',
        this.sidebarExpanded ? 'sidebar-expanded' : ''
      ]
    }
  },

  watch: {
    $route (){
      // On SM and smaller devices the sidebar is hidden when a navigation item
      // is clicked.
      if (Util.onSMAndSmaller() && this.sidebarExpanded) this.toggleSidebar();

      // This covers the case where the user logs out while the sidebar is
      // expanded.
      if (this.user.isAnonymous) this.sidebarExpanded = false
    }
  },

  methods: {
    toggleSidebar() {
      this.sidebarExpanded = !this.sidebarExpanded;
    }
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/theme.scss';
@import '@/assets/styles/media-queries.scss';
@import '@/assets/styles/bootstrap-tweaks.scss';


body {
  background: url($theme_background);
  color: $theme-color;
  font-family: $theme-font-family;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/*
 * Z-indices (I've grouped them here for clarity :).
 */
.sidebar {z-index: 999;}
.overlay {z-index: 998;}
.navbar  {z-index: 997;}
#content {z-index: 996;}


#content {
  position: absolute;
  top: $theme-navbar-height;
  left: 0;
  width: 100%;
  height: calc(100% - #{$theme-navbar-height});

  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

/*
 * Anonymous (aka unauthenticated) user.
 */
.layout.anonymous-user {
  /*
   * No sidebar ever for anonymous users! Just a plain ol' bootstrap responsive
   * navbar.
   */
  .sidebar {
    display: none;
  }
}

/*
 * Authenticated user.
 */
.layout.authenticated-user {
  /*
   * On SM and smaller the sidebar starts hidden and is toggled by the
   * hamburger. When it is visible it is *always* in its expanded state (where
   * the icon *and* text is shown for each navigation item), and we put an
   * overlay over the rest of the page.
   */
  @include on(sm-and-smaller) {
    .sidebar {
      display: none;
    }

    &.sidebar-expanded {
      .sidebar {
        display: block;
      }

      .overlay {
        display: block;
        opacity: 1;
      }
    }
  }

  /*
   * On MD and larger the sidebar is *always* visible and is either in its
   * non-expanded state where it just shows the navigation item icons, or in
   * its expanded state where it shows the icons and the text.
   */
  @include on(md-and-larger) {
    .navbar, #content {
      left: $theme-sidebar-width;
      width: calc(100% - #{$theme-sidebar-width});
    }

    &.sidebar-expanded {
      .navbar, #content {
        left: $theme-sidebar-expanded-width;
        width: calc(100% - #{$theme-sidebar-expanded-width});
      }
    }
  }
}
</style>
