import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

// Modules...
import activate from '@/lib/accounts/store/activate';
import auth from '@/lib/accounts/store/auth';
import passwordReset from '@/lib/accounts/store/password-reset';
import passwordResetRequest from '@/lib/accounts/store/password-reset-request';
import signup from '@/lib/accounts/store/signup';
import unsubscribe from '@/lib/accounts/store/unsubscribe';


const debug = process.env.NODE_ENV !== 'production';


Vue.use(Vuex)


export default new Vuex.Store({
  state    : {},
  actions  : {},
  mutations: {},
  strict   : debug,
  plugins  : debug ? [createLogger()] : [],
  modules  : {
    activate,
    auth,
    passwordReset,
    passwordResetRequest,
    signup,
    unsubscribe,
  },
})
