import accountsService from '@/lib/accounts/services';


export const PASSWORD_RESET_BEGIN   = 'PASSWORD_RESET_BEGIN';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';
export const PASSWORD_RESET_CLEAR   = 'PASSWORD_RESET_CLEAR';


export default {
  namespaced: true,

  state: {
    loading: false,
    completed: false,
    error: false,
    errors: {},
  },

  actions: {
    passwordReset({ commit }, { uid, token, password, passwordToo }) {
      commit(PASSWORD_RESET_BEGIN);
      return accountsService.passwordReset(uid, token, password, passwordToo)
        .then(() => commit(PASSWORD_RESET_SUCCESS))
        .catch((error) => commit(PASSWORD_RESET_FAILURE, error.response.data));
    },

    clear({ commit }) {
      commit(PASSWORD_RESET_CLEAR);
    },
  },

  mutations: {
    [PASSWORD_RESET_BEGIN](state) {
      state.loading = true;
      state.completed = false;
      state.error = false;
      state.errors = {};
    },
    [PASSWORD_RESET_SUCCESS](state) {
      state.loading = false;
      state.completed = true;
      state.error = false;
      state.errors = {};
    },
    [PASSWORD_RESET_FAILURE](state, errors) {
      state.loading = false;
      state.completed = false;
      state.error = true;
      state.errors = errors;
    },
    [PASSWORD_RESET_CLEAR](state) {
      state.loading = false;
      state.completed = false;
      state.error = false;
      state.errors = {};
    },
  },
};
