import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import store from '@/store'
import '@babel/polyfill'
//import vuetify from './plugins/vuetify';


Vue.config.productionTip = false


// Custom directive that gives the focus to a given element.
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function (el) {
    // Focus the element
    el.focus()
  }
})


window.App = new Vue({
  router,
  store,
  // vuetify,
  render: h => h(App)
}).$mount('#app')
