import accountsService from '@/lib/accounts/services';


export const SIGNUP_BEGIN   = 'SIGNUP_BEGIN';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILURE = 'SIGNUP_FAILURE';
export const SIGNUP_CLEAR   = 'SIGNUP_CLEAR';


export default {
  namespaced: true,

  state: {
    loading: false,
    completed: false,
    error: false,
    errors: {},
  },

  actions: {
    signup({ commit }, { username, email, password, passwordToo, name }) {
      commit(SIGNUP_BEGIN);
      return accountsService.signup(username, email, password, passwordToo, name)
        .then(() => commit(SIGNUP_SUCCESS))
        .catch((error) => commit(SIGNUP_FAILURE, error.response.data));
    },

    clear({ commit }) {
      commit(SIGNUP_CLEAR);
    },
  },

  mutations: {
    [SIGNUP_BEGIN](state) {
      state.loading = true;
      state.completed = false;
      state.error = false;
      state.errors = {};
    },

    [SIGNUP_SUCCESS](state) {
      state.loading = false;
      state.completed = true;
      state.error = false;
      state.errors = {};
    },

    [SIGNUP_FAILURE](state, errors) {
      state.loading = false;
      state.completed = false;
      state.error = true;
      state.errors = errors;
    },

    [SIGNUP_CLEAR](state) {
      state.loading = false;
      state.completed = false;
      state.error = false;
      state.errors = {};
    },
  },
};
