// Vue.
import Vue from 'vue'
import VueRouter from 'vue-router'

// Project.
import Home from '@/lib/site/views/Home.vue'
import store from '@/store';


Vue.use(VueRouter)


const requireAuthenticated = (to, from, next) => {
  if (!store.getters['auth/user'].isAuthenticated) {
    next({name: 'login', query: {redirect: to.fullPath}})
  } else {
    next();
  }
};


const redirectLogout = (to, from, next) => {
  store.dispatch('auth/logout')
    .then(() => next('/'))
};


const productionRoutes = [
  /////////////////////////////////////////////////////////////////////////////
  // Site.
  /////////////////////////////////////////////////////////////////////////////

  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about/',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/lib/site/views/About.vue'),
    pathToRegexpOptions: {strict: true}
  },

  /////////////////////////////////////////////////////////////////////////////
  // Accounts.
  /////////////////////////////////////////////////////////////////////////////

  {
    path: '/login/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '@/lib/accounts/views/Login.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/logout/',
    name: 'logout',
    beforeEnter: redirectLogout,
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/password-reset/',
    name: 'password-reset-request',
    component: () => import(/* webpackChunkName: "password-reset-request" */ '@/lib/accounts/views/PasswordResetRequest.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/password-reset/:uid/:token/',
    name: 'password-reset',
    component: () => import(/* webpackChunkName: "password-reset" */ '@/lib/accounts/views/PasswordReset.vue'),
    pathToRegexpOptions: {strict: true}
  },

  /////////////////////////////////////////////////////////////////////////////
  // Blog.
  /////////////////////////////////////////////////////////////////////////////

  {
    path: '/blog/',
    name: 'post-list',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "post-list" */ '@/lib/blog/views/PostList.vue'),
    pathToRegexpOptions: {strict: true}
  },

  /////////////////////////////////////////////////////////////////////////////
  // Chats.
  /////////////////////////////////////////////////////////////////////////////

  {
    path: '/cohorts/',
    name: 'cohort-list',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "cohort-list" */ '@/lib/chats/views/CohortList.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/cohorts/:id/',
    name: 'cohort-detail',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "cohort-detail" */ '@/lib/chats/views/CohortDetail.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/contracts/',
    name: 'contract-list',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "contract-list" */ '@/lib/chats/views/ContractList.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/help-centres/',
    name: 'help-centre-list',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "help-centre-list" */ '@/lib/chats/views/HelpCentreList.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/chats/',
    name: 'chat-list',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "chat-list" */ '@/lib/chats/views/ChatList.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/chats/moderation/',
    name: 'chat-moderation',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "chat-moderation" */ '@/lib/chats/views/ChatModeration.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/chats/broadcast/',
    name: 'broadcast',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "broadcast" */ '@/lib/chats/views/Broadcast.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/chats/:id/',
    name: 'chat-detail',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "chat-detail" */ '@/lib/chats/views/ChatDetail.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/users/',
    name: 'user-list',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "user-list" */ '@/lib/chats/views/UserList.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/users/:id/',
    name: 'user-detail',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "user-detail" */ '@/lib/chats/views/UserDetail.vue'),
    pathToRegexpOptions: {strict: true}
  },

  /////////////////////////////////////////////////////////////////////////////
  // Contact.
  /////////////////////////////////////////////////////////////////////////////

  {
    path: '/contact/',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '@/lib/contact/views/Contact.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/contact/sent/',
    name: 'contact-sent',
    component: () => import(/* webpackChunkName: "contact-sent" */ '@/lib/contact/views/ContactSent.vue'),
    pathToRegexpOptions: {strict: true}
  },

  /////////////////////////////////////////////////////////////////////////////
  // Content.
  /////////////////////////////////////////////////////////////////////////////

  {
    path: '/content/:path*/',
    name: 'content',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "content_" */ '@/lib/content/views/Content.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/delivery/:path*/',
    name: 'delivery',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "delivery" */ '@/lib/content/views/Delivery.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/training/:path*/',
    name: 'training',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "training" */ '@/lib/content/views/Training.vue'),
    pathToRegexpOptions: {strict: true}
  },

  /////////////////////////////////////////////////////////////////////////////
  // DART.
  /////////////////////////////////////////////////////////////////////////////

  {
    path: '/dart/board/',
    name: 'dart-board',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "dart-board" */ '@/lib/dart/views/DartBoard.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/dart/desk/',
    name: 'dart-desk',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "dart-desk" */ '@/lib/dart/views/DartDesk.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/dart/journeys/:id/',
    name: 'journey-detail',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "journey-detail" */ '@/lib/dart/views/JourneyDetail.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/dart/stages/:id/',
    name: 'stage-detail',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "stage-detail" */ '@/lib/dart/views/StageDetail.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/dart/tickets/:id/',
    name: 'ticket-detail',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "ticket-detail" */ '@/lib/dart/views/TicketDetail.vue'),
    pathToRegexpOptions: {strict: true}
  },

  /////////////////////////////////////////////////////////////////////////////
  // Meetings.
  /////////////////////////////////////////////////////////////////////////////

  {
    path: '/meetings/',
    name: 'meeting-list',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "meeting-list" */ '@/lib/meetings/views/MeetingList.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/meetings/:id/',
    name: 'meeting-detail',
    beforeEnter: requireAuthenticated,
    component: () => import(/* webpackChunkName: "meeting-detail" */ '@/lib/meetings/views/MeetingDetail.vue'),
    pathToRegexpOptions: {strict: true}
  },

  /////////////////////////////////////////////////////////////////////////////
  // Not Found/Error/Offline etc.
  /////////////////////////////////////////////////////////////////////////////

  {
    path: '/offline/',
    name: 'offline',
    component: () => import(/* webpackChunkName: "offline" */ '@/lib/site/views/Offline.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/oops/',
    name: 'oops',
    component: () => import(/* webpackChunkName: "oops" */ '@/lib/site/views/Oops.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import(/* webpackChunkName: "not-found" */ '@/lib/site/views/NotFound.vue')
  }
]

const developmentRoutes = [
  {
    path: '/activate/:uid/:token/',
    name: 'activate',
    component: () => import(/* webpackChunkName: "activate" */ '@/lib/accounts/views/Activate.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/signup/',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '@/lib/accounts/views/Signup.vue'),
    pathToRegexpOptions: {strict: true}
  },
  {
    path: '/unsubscribe/:uid/:token/',
    name: 'unsubscribe',
    component: () => import(/* webpackChunkName: "unsubscribe" */ '@/lib/accounts/views/Unsubscribe.vue'),
    pathToRegexpOptions: {strict: true}
  },
]

let routes = productionRoutes;
if (process.env.NODE_ENV == 'development') {
  routes = developmentRoutes.concat(routes);
}

const router = new VueRouter({
  mode: 'history',
  // fixme: We had to comment this out!
  //base: process.env.BASE_URL,
  routes: routes
})

export default router
